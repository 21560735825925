<template>
    <div id="app" :style="cssVars">
         <!-- <div class="position-fixed" style="top: 10px; left: 10px; z-index: 9999">
            <span class="badge bg-primary d-block d-sm-none">xs</span>
            <span class="badge bg-secondary d-none d-sm-block d-md-none">sm</span>
            <span class="badge bg-success d-none d-md-block d-lg-none">md</span>
            <span class="badge bg-dark d-none d-lg-block d-xl-none">lg</span>
            <span class="badge bg-info d-none d-xl-block">xl</span>
        </div> -->

        <b-overlay :show="isLoading" class="flex-fill d-flex flex-column" v-bind:class="`${client}`" spinner-variant="`${client}`">
            <template v-if="$route.matched.length">
                <b-navbar toggleable="sm" type="dark" variant="gray-9">
                    <b-navbar-toggle target="navbar-toggle-collapse"></b-navbar-toggle>

                    <b-navbar-brand>
                        <img :src="logoUrl" v-bind:class="`${client === 'manergy' ? 'bg-white px-2' : ''}`" alt="" />
                    </b-navbar-brand>

                    <b-collapse id="navbar-toggle-collapse" is-nav>
                        <b-navbar-nav class="align-items-center">
                            <b-nav-item to="/">ACCUEIL</b-nav-item>
                            <b-nav-item to="/calendrier">CALENDRIER</b-nav-item>
                        </b-navbar-nav>

                        <b-navbar-nav class="ml-auto align-items-center">
                            <b-badge v-if="isLoggedIn && isAdmin" variant="light">ADMIN</b-badge>
                            <b-nav-item v-if="isLoggedIn" to="/logout">DÉCONNEXION</b-nav-item>
                            <b-nav-item v-if="!isLoggedIn" to="/login">CONNEXION</b-nav-item>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
                <router-view></router-view>
            </template>
            <template v-else-if="isLoggedIn">
                <NotFound />
            </template>
        </b-overlay>
    </div>
</template>

<script>
import auth from "./services/auth";
import loader from "./services/loader";
import NotFound from "./components/_404";
import { getCurrentClient, getLogoUrl, getBgHomeUrl, getBgDayUrl, getBgCalendarUrl, isStarted } from "./services/client";

export default {
    components: {
        NotFound,
    },
    data: () => {
        const _client = getCurrentClient();

        return {
            isLoggedIn: auth.getIfLoggedIn(),
            isAdmin: auth.getIfIsAdmin(),
            client: _client,
            logoUrl: getLogoUrl(_client),
            isStarted: isStarted(),
            isLoading: false,
        };
    },
    created() {
        auth.onChange = (isLoggedIn) => {
            this.isLoggedIn = isLoggedIn;
            this.isAdmin = auth.getIfIsAdmin();
        };
        loader.showLoader = () => (this.isLoading = true);
        loader.hideLoader = () => (this.isLoading = false);
    },
    computed: {
        cssVars() {
            let bgHomeUrl = getBgHomeUrl(this.client);
            let bgDayUrl = getBgDayUrl(this.client);
            let bgCalendarUrl = getBgCalendarUrl(this.client);

            return {
                "--bg-home": `url(${bgHomeUrl}) no-repeat center center`,
                "--bg-day": `url(${bgDayUrl}) no-repeat center center`,
                "--bg-calendar": `url(${bgCalendarUrl}) no-repeat center center`,
            };
        },
    },
};
</script>

<style>
.login:before,
.home:before {
    background: var(--bg-home);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.calendrier:before {
    background: var(--bg-calendar);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.opened-case:before {
    background: var(--bg-day);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
