<template>
    <div class="opened-case flex-fill position-relative text-white">
        <div class="rainsequins" v-if="!elements || !isStarted">
            <div class="rainsequin" v-for="i in 500" v-bind:key="i"></div>
        </div>

        <div class="content d-flex-center flex-fill h-100" v-if="elements && isStarted">
            <div
                class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100"
                v-if="isStarted"
                style="width:700px"
            >
                <h1 class="mt-3 mb-3 label-date">{{ currentDate }}</h1>

                <div v-if="!isTooEarly">
                    <VueEditor class="invisible" style="height:0px;" />

                    <div v-for="element in elements" :key="element.ordre" class="my-3">
                        <div class="ql-snow" v-if="element.type == 'text'">
                            <div v-html="element.valeur" class="text-content ql-editor p-1"></div>
                        </div>

                        <div v-if="element.type == 'video'">
                            <iframe
                                class="video-case my-3"
                                :src="element.url"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </div>

                        <div v-if="element.type == 'santa'">
                            <embed
                                class="santa-case"
                                :src="`https://co.innov.events/santa-jump-${client}-embed`"
                                type="text/html"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />

                            <div class="text-center">
                                <a
                                    target="_blank"
                                    class="btn"
                                    v-bind:class="`btn-${client}`"
                                    :href="`https://co.innov.events/santa-jump-${client}`"
                                >
                                    Lien externe en cas de lenteur
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- si on est avant le jour selectionne -->
                <TooEarly v-else />
            </div>
        </div>

        <div class="content w-100 position-absolute" v-if="!elements || !isStarted" style="top:35%">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100 text-center">
                <h1 class="mt-3 mb-2 label-date">
                    {{ !isStarted ? "Un peu de patience, rendez-vous le 1 décembre !" : "Chargement du contenu..." }}
                </h1>
            </div>
        </div>

        <div class="x" v-if="client !== 'kbane' && (!elements || !isStarted)">
            <img class="y" src="@/assets/img/paillettes.png" alt="codepen" />
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import auth from "../services/auth";
import { getCurrentClient, isStarted } from "../services/client";

import { getDayContent } from "../services/cases-service";
import TooEarly from "@/cases/TooEarly";
import { VueEditor } from "vue2-editor";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
    components: {
        TooEarly,
        VueEditor,
    },
    data: function() {
        const day = this.$route.params.day || localStorage.day;

        if (!day) {
            router.push({ name: "calendrier" });
            return;
        }

        localStorage.day = day;
        const currentDate = new Date(Date.UTC(2023, 11, day, 0, 0, 0));

        const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
        };

        return {
            isStarted: isStarted() || auth.getIfIsAdmin(),
            currentDate: capitalize(currentDate.toLocaleDateString("fr-FR", options)),
            day,
            elements: null,
            isTooEarly: false,
            client: getCurrentClient(),
        };
    },
    asyncComputed: {
        async elements() {
            var _elements = [];
            const day = this.$route.params.day || localStorage.day;
            const today = new Date();

            if (day == today.getDate() && today.getHours() < 8) {
                return null;
            }

            await getDayContent({ day, token: auth.getToken() })
                .then((result) => {
                    const data = result.data;
                    if (data == -1) {
                        this.isTooEarly = true;
                    } else {
                        _elements = data;
                    }
                })
                .catch(function(error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            return _elements;
        },
    },
};
</script>
