import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import cookies from "../plugins/cookies";

var _isAdmin = null;
const _getIfLoggedIn = () => cookies.exists("token");

export default {
    name: "auth",

    async login(client, code, cb) {
        cb = arguments[arguments.length - 1];

        if (_getIfLoggedIn()) {
            if (cb) cb(true);
            this.onChange(true);
            return;
        }
        var params = { client, code };
        var token = window.btoa(JSON.stringify(params));

        // call login function, return token and if user is admin

        const result = await httpsCallable(functions, "login")(token)
            .catch(function(error) {
                console.log("Une erreur est survenue", error.code, error.message, error.details);
                cb(false);
                this.onChange(false);
            });

        const data = result.data;
        if (data.token) {
            cookies.set("token", data.token);
            cookies.set("isAdmin", data.isAdmin);

            cb(true);
            this.onChange(true);
        } else {
            cb(false);
            this.onChange(false);
        }
    },

    logout(cb) {
        cookies.remove("token");
        cookies.remove("isAdmin");
        _isAdmin = null;

        if (cb) cb();
        this.onChange(false);
    },

    getToken: () => cookies.get("token"),
    getIfLoggedIn: _getIfLoggedIn,
    getIfIsAdmin: () => {
        if (_isAdmin == null) {
            _isAdmin = JSON.parse(cookies.get("isAdmin"));
        }

        return _isAdmin;
    },
    onChange() {},
};
