<template>
    <div class="calendrier row no-gutters flex-fill" :style="cssVars">
        <div class="snowflakes">
            <div class="snowflake" v-for="i in 50" v-bind:key="i"></div>
        </div>

        <div
            class="col-3 col-sm-3 col-md-3 col-lg-2 case d-flex-center rounded p-1 p-lg-3"
            v-for="day in days"
            v-bind:key="day"
        >
            <b-btn variant="outline-white w-100 h-100" v-bind:class="passedClass(day)" @click="openCase(day)">
                {{ day }}
            </b-btn>

            <i
                @click="openCaseEditor(day)"
                class="fa-solid fa-pencil position-absolute text-white"
                v-bind:class="`text-hover-${client}`"
                v-if="isAdmin"
                style="bottom:2rem;right:2rem;"
            ></i>
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import { getCurrentClient, isStarted } from "../services/client";
import auth from "../services/auth";

export default {
    data() {
        let todayDay = new Date().getDate();
        let days = [16, 10, 2, 21, 7, 17, 3, 11, 9, 13, 5, 22, 6, 20, 15, 18, 24, 1, 19, 23, 14, 4, 8, 12];
        let client = getCurrentClient();
        let isAdmin = auth.getIfIsAdmin();

        if (isAdmin) {
            days = days.sort((a, b) => a - b);
        }

        if (client == "simt") {
            var i1 = days.indexOf(2);
            days[i1] = 25;
        }

        this.$gtag.event("open_calendar", {
            event_category: "calendar",
            event_label: this.client,
        });

        return {
            days,
            isAdmin,
            client,
            todayDay,
            isStarted: isStarted() || isAdmin,
        };
    },
    methods: {
        openCase: function(day) {
            if (this.client == "demo" && [3, 16, 7, 22].indexOf(day) === -1) return;

            this.$gtag.event("open_case", {
                event_category: "calendar",
                event_label: this.client,
                value: day,
            });
            router.push({ name: "case", params: { day: day } });
        },
        openCaseEditor: function(day) {
            router.push({ name: "case-editor", params: { day: day } });
        },
        passedClass(_day) {
            if (this.client == "demo") {
                return [3, 16, 7, 22].indexOf(_day) > -1 ? "passed" : "";
            } else {
                return this.isStarted && _day < this.todayDay ? "-passed" : "";
            }
        },
    },
    computed: {
        cssVars() {
            return {
                "--case-opacity": this.isAdmin ? 1 : 0.15,
            };
        },
    },
};
</script>

<style scoped>
body {
    background-color: #212529 !important;
}
</style>
