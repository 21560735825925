<template>
    <b-container class="not-found mt-5 pt-5 text-center">
        <div v-bind:class="`text-${currentClient}`" class="text-bold h1">Page introuvable</div>
        <router-link to="/">Retour à l'accueil</router-link>
    </b-container>
</template>

<script>
import { getCurrentClient } from "@/services/client";

export default {
    data: () => ({
        currentClient: getCurrentClient(),
    }),
};
</script>

<style>
body {
    background-color: #212529 !important;
}
</style>
