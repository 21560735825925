<template>
    <div class="opened-case flex-fill position-relative">
        <div class="content d-flex-center flex-fill h-100" v-if="contenu">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg text-center" style="min-width: 600px; max-width: 800px">
                <h1 class="mt-3 mb-2 text-white label-date">{{ id ? "Modification" : "Création" }} de la section</h1>

                <div class="d-flex flex-column align-items-center w-100">
                    <div class="row">
                        <div class="col-auto text-white"><label>Type de la section :</label></div>
                        <div class="col">
                            <b-form-radio-group v-model="selectedType" :options="types" class="mb-3 text-white"></b-form-radio-group>
                        </div>
                    </div>

                    <div id="editors" class="align-self-start w-100 my-3">
                        <div v-if="selectedType == 'text'">
                            <VueEditor class="bg-white" v-model="texte" />
                        </div>

                        <div v-else-if="selectedType == 'video'">
                            <!-- <b-input-group prepend="https://www.youtube.com/embed/" class="mt-3">
                                <b-form-input
                                    id="url_youtube"
                                    v-model="urlYoutube"
                                    placeholder="[ID_VIDEO]"
                                ></b-form-input>
                            </b-input-group> -->

                            <b-form-group
                                label="Url de la vidéo YouTube :"
                                label-class="text-white"
                                label-for="url_youtube"
                                description="ID_VIDEO : identifiant de la vidéo YouTube que vous pouvez retrouver dans l'url de votre vidéo"
                                class="mb-0 text-left"
                            >
                                <b-form-input
                                    id="url_youtube"
                                    v-model="urlYoutube"
                                    placeholder="https://www.youtube.com/embed/[ID_VIDEO]"
                                ></b-form-input>
                            </b-form-group>
                            <div class="w-100 mt-2 text-white small cursor-pointer text-left" @click="urlYoutube = 'https://www.youtube.com/embed/'">
                                Cliquer pour préremplir <u>https://www.youtube.com/embed/</u>
                            </div>
                        </div>
                    </div>
                    <div>
                        <router-link tag="button" class="btn btn-normal text-white my-3" :to="{ name: 'case-editor', params: { day } }">
                            <b-icon icon="x"></b-icon>
                            <span class="ml-1">Annuler</span>
                        </router-link>
                        <button @click="saveSection" v-bind:class="`btn-${client}`" class="btn text-white ml-2">
                            <b-icon icon="check"></b-icon>
                            <span class="ml-1">Enregistrer</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="content w-100 position-absolute" v-if="!contenu" style="top: 35%">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100 text-center">
                <h1 class="mt-3 mb-2 label-date">Un peu de patience...</h1>
            </div>
        </div>

        <div class="x" v-if="!contenu">
            <img class="y" src="@/assets/img/gift.png" alt="codepen" />
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import auth from "../services/auth";
import loader from "../services/loader";
import { getCurrentClient } from "../services/client";
import { getSectionContent, saveSectionContent } from "../services/cases-service";
import { VueEditor } from "vue2-editor";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
    components: {
        VueEditor,
    },
    data: function() {
        const day = this.$route.params.day;
        const id = this.$route.params.id;
        const ordre = this.$route.params.index;
        const currentDate = new Date(Date.UTC(2023, 11, day, 0, 0, 0));
        const client = getCurrentClient();

        const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
        };

        return {
            currentDate: capitalize(currentDate.toLocaleDateString("fr-FR", options)),
            day,
            id,
            client,
            contenu: null,
            types: null,
            ordre,
            selectedType: null,
            urlYoutube: null,
            texte: null,
            urlImage: null,
        };
    },
    methods: {
        saveSection: async function() {
            loader.showLoader();

            const contenu = {
                id: this.id,
                ordre: +this.ordre,
                jour: +this.day,
                type: this.selectedType,
                valeur: "",
                url: "",
            };

            switch (contenu.type) {
                case "text":
                    contenu.valeur = this.texte;
                    break;
                case "video":
                    contenu.url = this.urlYoutube;
                    break;
            }

            await saveSectionContent({ contenu, token: auth.getToken() })
                .then((result) => {
                    const success = result.data;
                    if (success) {
                        router.push({ name: "case-editor", params: { day: this.day } });
                    } else {
                        alert("KO");
                    }
                })
                .catch(function(error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            loader.hideLoader();
        },
    },
    asyncComputed: {
        async contenu() {
            var _contenu;

            this.types = [
                { value: "text", text: "Texte", disabled: !!this.id },
                { value: "video", text: "Vidéo YouTube", disabled: !!this.id },
            ];

            // si la section existe on la charge depuis la base
            if (this.id) {
                await getSectionContent({ id: this.id, token: auth.getToken() })
                    .then((result) => {
                        const data = result.data;
                        _contenu = data;
                    })
                    .catch(function(error) {
                        console.error("Une erreur est survenue", error.code, error.message, error.details);
                    });

                switch (_contenu.type) {
                    case "text":
                        this.texte = _contenu.valeur;
                        break;
                    case "video":
                        this.urlYoutube = _contenu.url;
                        break;
                }

                this.selectedType = _contenu.type;

                return _contenu;
            } else {
                this.selectedType = "text";

                _contenu = {
                    client: getCurrentClient(),
                    jour: this.day,
                    ordre: this.ordre,
                    type: "text",
                    valeur: null,
                };
            }

            return _contenu;
        },
    },
};
</script>
