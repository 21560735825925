import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "@/plugins/bootstrap-vue";
import { firestorePlugin } from "vuefire";
import AsyncComputed from "vue-async-computed";
import VueGtag from "vue-gtag";
import VueCookies from "vue-cookies";

require(`@/assets/styles/site.scss`);

Vue.use(AsyncComputed);
Vue.use(firestorePlugin);
Vue.use(VueGtag, {
    config: { id: "G-14GCH33BY0" },
});
Vue.use(VueCookies, { expires: "45d" });
Vue.config.productionTip = false;

import App from "@/App";
import router from "./services/router";
import cookies from "./plugins/cookies";

const hostname = window.location.hostname.replace(/www\./, "");
cookies.set = (key, value) => {
    Vue.$cookies.set(key, value, "45d", null, hostname);
};
cookies.get = (key) => Vue.$cookies.get(key);
cookies.exists = (key) => Vue.$cookies.isKey(key);
cookies.remove = (key) => Vue.$cookies.remove(key, null, hostname);

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
